import React from 'react';

import Slick from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

export default class SliderItems extends React.Component {

  constructor(props) {
    super(props);

    this.internal = {
      statusPlay : 'stop',
    };

    this._prev = this._prev.bind(this);
    this._next = this._next.bind(this);
    this._play = this._play.bind(this);
    this._stop = this._stop.bind(this);
  }

  _prev = () => {
    this.slick.slickPrev();
  };

  _next = () => {
    this.slick.slickNext();
  };

  _play_n_stop = () => {
    if (this.internal.statusPlay == 'play') {
      this._stop();
    }
    else {
      this._play();
    }
  };

  _play = () => {
    this.internal.statusPlay = 'play';
    this.slick.slickPlay();
  };

  _stop = () => {
    this.internal.statusPlay = 'stop';
    this.slick.slickPause();
  };

  _renderActions = () => {
    if (this.props.count <= 0) {
      return '';
    }

    let actions = {
      play_n_stop : (
        <button className={'slider--play-n-stop'} onClick={this._play_n_stop}>
          Lancer/arrêter le défilement des images
        </button>
      ),
      prev : (
        <button className={'slider--prev'} onClick={this._prev}>
          Voir l'image précédente
        </button>
      ),
      next : (
        <button className={'slider--next'} onClick={this._next}>
          Voir l'image suivante
        </button>
      ),
    };

    return (
      <>
        {this.props.playNStop ? actions.play_n_stop : null}
        {this.props.prev ? actions.prev : null}
        {this.props.next ? actions.next : null}
      </>
    );
  };

  render() {
    return (
      <>
        <Slick ref={v => (this.slick = v)} {...this.props.slickSettings}>
          {this.props.items}
        </Slick>
        {this._renderActions()}
      </>
    );
  }

}
