import React from 'react';

import SelectField from './SelectField.jsx';

export default class Select2Field extends React.Component {

  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    // Do not need for select
    // e.preventDefault();

    this.props.onChange(e);
  };

  render() {
    return (
      <SelectField
        onChange={this.handleChange}
        {...this.props}/>
    );
  }

}
