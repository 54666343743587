import React from 'react';

export default class SelectField extends React.Component {

  constructor(props) {
    super(props);
  }

  handleChange = (e) => {
    // Do not need for select
    // e.preventDefault();

    this.props.onChange(e);
  };

  render() {
    this.props.attributes = this.props.attributes || {};

    if (this.props.required) {
      this.props.attributes['required'] = 'required';
      this.props.attributes['aria-required'] = 'true';
    }

    return (
      <select name={this.props.name} id={this.props.name} onChange={this.handleChange} {...this.props.attributes}>
        {
          !this.props.value || !this.props.required
          ? <option value={''}>- Sélectionner -</option>
          : ''
        }

        {
          Object.keys(this.props.options).map(optionValue => {
            let attributes = {};

            if (optionValue == this.props.value) {
              attributes.selected = 'selected';
            }

            return (
              <option value={optionValue} {...attributes}>{this.props.options[optionValue]}</option>
            )
          })
        }
      </select>
    );
  }
}
