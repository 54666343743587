import $ from 'jquery';
import slick from 'slick-carousel';

document.addEventListener('DOMContentLoaded', () => {
  let slickElements,
    slickElement,
    slickItems,
    slickPrev,
    slickNext,
    slickDots;

  /* HEADER : banner */
  slickElement = document.querySelector('.header--banner');

  if (
    typeof slickElement != 'undefined'
    && slickElement != null
  ) {
    slickItems = slickElement.querySelector('.slider--items');
    slickPrev = slickElement.querySelector('.slider--prev');
    slickNext = slickElement.querySelector('.slider--next');
    slickDots = slickElement.querySelector('.slider--dots');

    $(slickItems).slick({
      accessibility: true, // enable tab navigation WAI
      arrows: true,
      dots: slickDots
        && typeof slickDots != 'undefined',
      fade: false,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: true,
      prevArrow: slickPrev,
      nextArrow: slickNext,
      appendDots: slickDots,
      slidesToShow: 1,
      slidesToScroll: 1,
      zIndex: 0,
      variableWidth: false,
    });
  }

  /* HP : Advantage */
  slickElement = document.querySelector('.block--hp--advantage');

  if (
    typeof slickElement != 'undefined'
    && slickElement != null
  ) {
    slickItems = slickElement.querySelector('.slider--items');
    slickPrev = slickElement.querySelector('.slider--prev');
    slickNext = slickElement.querySelector('.slider--next');

    $(slickItems).slick({
      accessibility: true, // enable tab navigation WAI
      arrows: true,
      dots: false,
      fade: false,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: true,
      prevArrow: slickPrev,
      nextArrow: slickNext,
      slidesToShow: 4,
      slidesToScroll: 4,
      zIndex: 0,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    });
  }

  /* HP : News */
  slickElement = document.querySelector('.block--hp--news');

  if (
    typeof slickElement != 'undefined'
    && slickElement != null
  ) {
    slickItems = slickElement.querySelector('.slider--items');
    slickPrev = slickElement.querySelector('.slider--prev');
    slickNext = slickElement.querySelector('.slider--next');

    $(slickItems).slick({
      accessibility: true, // enable tab navigation WAI
      arrows: true,
      dots: false,
      fade: false,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: true,
      prevArrow: slickPrev,
      nextArrow: slickNext,
      slidesToShow: 4,
      slidesToScroll: 4,
      zIndex: 0,
      variableWidth: false,
      responsive: [
        {
          breakpoint: 992,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        },
      ]
    });
  }

  /* ELTO : labeling */
  slickElement = document.querySelector('.block--elto--labeling');

  if (
    typeof slickElement != 'undefined'
    && slickElement != null
  ) {
    slickItems = slickElement.querySelector('.slider--items');
    slickPrev = slickElement.querySelector('.slider--prev');
    slickNext = slickElement.querySelector('.slider--next');

    $(slickItems).slick({
      accessibility: true, // enable tab navigation WAI
      arrows: true,
      dots: false,
      fade: false,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: false,
      prevArrow: slickPrev,
      nextArrow: slickNext,
      slidesToShow: 3,
      slidesToScroll: 3,
      zIndex: 0,
      variableWidth: true,
      responsive: [
        {
          breakpoint: 768,
          settings: {
            slidesToScroll: 1,
          }
        },
      ]
    });
  }

  /* ELTO : discount & offer */
  slickElement = document.querySelector('.block--elto--slider');

  if (
    typeof slickElement != 'undefined'
    && slickElement != null
  ) {
    slickItems = slickElement.querySelector('.slider--items');
    slickPrev = slickElement.querySelector('.slider--prev');
    slickNext = slickElement.querySelector('.slider--next');

    $(slickItems).slick({
      accessibility: true, // enable tab navigation WAI
      arrows: true,
      dots: false,
      fade: false,
      autoplay: false,
      autoplaySpeed: 5000,
      infinite: true,
      prevArrow: slickPrev,
      nextArrow: slickNext,
      slidesToShow: 1,
      slidesToScroll: 1,
      zIndex: 0,
      variableWidth: false,
    });
  }

  /* WYSIWYG : slider */
  slickElements = document.querySelectorAll('.custom--slider');

  if (
    typeof slickElements != 'undefined'
    && slickElements != null
    && slickElements.length > 0
  ) {
    Object.keys(slickElements).map((index) => {
      let slickElement = slickElements[index];

      slickItems = slickElement.querySelector('.slider--items');
      slickPrev = slickElement.querySelector('.slider--prev');
      slickNext = slickElement.querySelector('.slider--next');

      $(slickItems).slick({
        accessibility: true, // enable tab navigation WAI
        arrows: slickPrev
          && typeof slickPrev != 'undefined'
          && slickNext
          && typeof slickNext != 'undefined',
        dots: slickElement.getAttribute('data-dots')
          && slickElement.getAttribute('data-dots') == 'true',
        fade: false,
        autoplay: false,
        autoplaySpeed: 5000,
        infinite: true,
        prevArrow: slickPrev,
        nextArrow: slickNext,
        slidesToShow: 1,
        slidesToScroll: 1,
        zIndex: 0,
        variableWidth: false,
      });
    });
  }


}, false);
