import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Flag from 'react-world-flags';

class SvgCountryFlag extends Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Flag code={ this.props.code } alt={ "" }/>
    );
  }

}

export default SvgCountryFlag;

document.addEventListener('DOMContentLoaded', () => {
  const domContainers = document.querySelectorAll('.icon--country-flag');

  Object.keys(domContainers).map((index) => {
    let domContainer = domContainers[index];

    ReactDOM.render(<SvgCountryFlag code={domContainer.getAttribute('data-country')}/>, domContainer);
  });

}, false);
