import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import FormGroup from './Form/FormGroup.jsx';

class FakeSelectForLinks extends Component {

  constructor(props) {
    super(props);
  }

  onChange = (e) => {
    window.location.href = e.target.value;
  };

  render() {
    return (
      <FormGroup
        type={'select'}
        name={'multisite'}
        value={this.props.value}
        options={this.props.options}
        required={true}
        onChange={this.onChange}/>
    );
  }

}

export default FakeSelectForLinks;

document.addEventListener('DOMContentLoaded', () => {
  const domContainers = document.querySelectorAll('.fake-select-for-links');

  Object.keys(domContainers).map((index) => {
    let domContainer = domContainers[index];

    let options = {};
    let links = domContainer.querySelectorAll('ul a');
    Object.keys(links).map((index) => {
      let href = links[index].getAttribute('href');
      let label = links[index].textContent;

      options[href] = label;
    });

    ReactDOM.render(<FakeSelectForLinks value={domContainer.querySelector('a').getAttribute('href')} options={options}/>, domContainer);
  });

}, false);
