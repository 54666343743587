import React from 'react';
import CheckboxesRadiosField from './TemplateElement/CheckboxesRadiosField.jsx';

export default class CheckboxesField extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      values: {},
    };
  }

  handleChange = (e) => {
    // Do not need for checkbox/radio
    // For standard checking behaviour
    // e.preventDefault();

    let formElementValue = e.target.value;

    if (e.target.checked) {
      this.state.values[formElementValue] = formElementValue;
    }
    else {
      delete this.state.values[formElementValue];
    }

    this.props.onChange({
      preventDefault: () => {},
      target: {
        getAttribute: (att) => {
          switch (att) {
            case 'name' : return this.props.name;
            default : return null;
          }
        },
        value: Object.keys(this.state.values),
      }
    });
  };

  render() {
    this.props.type = 'checkbox';
    let {onChange,...other} = this.props;

    return (
      <CheckboxesRadiosField onChange={this.handleChange} {...other} />
    );
  }

}
