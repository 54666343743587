import React from 'react';

export default class ViewModeHpMember extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className={'block--hp--member--item'}>
        <a className={'data-hp-conseils block--hp--member--item--link'} href={this.props.link}>
          <div className={'block--hp--member--item--picture'}>
            <img src={this.props.img}/>
          </div>
          <div className={'block--hp--member--item--content'}>
            <h3 className={'block--hp--member--item--title'}>{this.props.title}</h3>
            <div className={'block--hp--member--item--description'}>{this.props.description}</div>
            <div className={'block--hp--member--item--show-more btn--on-light-bg'}>{this.props.showMore}</div>
          </div>
        </a>
      </div>
    );
  }

}
