import van11yAccessibleModal from 'van11y-accessible-modal-window-aria';
import AddToAny from './addToAny';

document.addEventListener('DOMContentLoaded', () => {
  let modalButtonAddtoany = document.querySelector('.header--cta--item--addtoany--modal-button');

  if (
    typeof modalButtonAddtoany != 'undefined'
    && modalButtonAddtoany != null
  ) {
    // Open Modal
    modalButtonAddtoany.addEventListener('click', (e) => {
      let addToAnyClass = new AddToAny();
      addToAnyClass.init();

      // Close modal
      let modalCloseButtons = document.querySelectorAll('.modal--cta#js-modal-overlay, .modal--cta#js-modal-close');

      Object.keys(modalCloseButtons).map((index) => {
        let modalCloseButton = modalCloseButtons[index];

        modalCloseButton.addEventListener('click', (e) => {
          let addToAnyClass = new AddToAny();
          addToAnyClass.init();
        });
      });
    });
  }
});

