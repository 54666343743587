class AddToAny {

  constructor() {
    this._existsAddtoany();
  }

  init() {
    if (
      typeof a2a != 'undefined'
      && typeof a2a.init != 'undefined'
    ) {
      a2a.init();
    }
  }

  changeTwitterTitle(title) {
    a2a_config.templates = a2a_config.templates || {};
    a2a_config.templates.twitter = {
      text: title
    };
  }

  _existsAddtoany() {
    if (typeof a2a_config == 'undefined') {
      window.a2a_config = {};
    }
  }

}

export default AddToAny;

document.addEventListener('DOMContentLoaded', () => {
  let twitterButton = document.querySelector('.a2a_button_twitter');

  if (
    typeof twitterButton != 'undefined'
    && twitterButton != null
    && Drupal
    && Drupal.settings
    && Drupal.settings.wbt_addtoany
    && Drupal.settings.wbt_addtoany.twitter_title
  ) {
    let addToAnyClass = new AddToAny();

    addToAnyClass.changeTwitterTitle(Drupal.settings.wbt_addtoany.twitter_title);
  }
});
