import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import * as CONSTANTS from '../../utlis/constants';
import {Fetcher} from '../../utlis/fetcher';
import ViewModeHpMember from '../ViewMode/ViewModeHpMember.jsx';
import Slider from '../View/Type/Slider.jsx';

class HpMemberBlock extends Component {

  constructor(props) {
    super(props);

    this.state = {
      status : CONSTANTS.APP_STATUS.LOADING,
      items : [],
      errorMessages : {},
    };

    this._getItems();
  }

  _getItems = () => {
    try {
      Fetcher.request('/' + Drupal.settings.current_language + '/home/membre', 'get')
        .then((response) => {
          if (
            typeof response.status !== 'undefined'
            && response.status == 'success'
          ) {
            this.setState({
              status : CONSTANTS.APP_STATUS.LOADED,
              items : response.query,
              errorMessages : {},
            });
          }
          else {
            this.setState({
              status : CONSTANTS.APP_STATUS.ERROR,
              items : [],
              errorMessages : Fetcher.getParsedErrorMessages(response),
            });
          }
        });
    }
    catch (error) {
      this.setState({
        status : CONSTANTS.APP_STATUS.ERROR,
        items : [],
        errorMessages : {error : error.message || 'Unknown error'},
      });
    }
  };

  render() {
    if (
      this.state.status == CONSTANTS.APP_STATUS.LOADED
      && Object.keys(this.state.items).length > 0
    ) {
      let slickSettings = {
        slide: React.Fragment,
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        adaptiveHeight: false,
        autoplay: false,
        autoplaySpeed: 2000,
        fade: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      };

      let items = [];
      Object.keys(this.state.items).map((index) => {
        let item = this.state.items[index];

        items.push(
          <ViewModeHpMember
            img={item.img_heb}
            title={item.title_heb}
            description={item.description_heb}
            link={item.link_heb}
            showMore={this.props.showMore}
          />
        );

        items.push(
          <ViewModeHpMember
            img={item.img_elto}
            title={item.title_elto}
            description={item.description_elto}
            link={item.link_elto}
            showMore={this.props.showMore}
          />
        );
      })

      return (
        <>
          <h2 className={'title'}>{this.props.title}</h2>
          <div className={'block--hp--member--slider slider'}>
            <Slider
              count={Object.keys(this.state.items).length * 2}
              items={items}
              slickSettings={slickSettings}
              prev={true}
              next={true}
            />
          </div>
        </>
      );
    }
    else {
      return '';
    }
  }

}

export default HpMemberBlock;

document.addEventListener('DOMContentLoaded', () => {
  const domContainer = document.querySelector('.block--hp--member');

  if (
    typeof domContainer == 'undefined'
    || domContainer == null
  ) {
    return;
  }

  ReactDOM.render(
    <HpMemberBlock
      title={domContainer.getAttribute('data-title')}
      showMore={domContainer.getAttribute('data-show-more')}/>,
    domContainer
  );

}, false);
