/*
* This function is used for avoid robots simulate click on <a>
* OR to change click behavior to double-click
* */

document.addEventListener('DOMContentLoaded', () => {
  let specialLinks = null;

  specialLinks = document.querySelectorAll('a[data-after-load]');

  if (
    typeof specialLinks != 'undefined'
    && specialLinks != null
  ) {
    Object.keys(specialLinks).map((index) => {
      let specialLink = specialLinks[index];

      specialLink.addEventListener('click', (e) => {
        e.preventDefault();
        window.location.href = e.currentTarget.getAttribute('data-after-load') || '#';
      });
    });
  }


  specialLinks = document.querySelectorAll('[data-href]');

  if (
    typeof specialLinks != 'undefined'
    && specialLinks != null
  ) {
    Object.keys(specialLinks).map((index) => {
      let specialLink = specialLinks[index];

      specialLink.addEventListener('dblclick', (e) => {
        e.preventDefault();
        window.location.href = e.currentTarget.getAttribute('data-href') || '#';
      });
    });

  }

}, false);
