class collapse {

  constructor(collapseElementHtmlTags, openButton, closeWhenLooseFocus) {
    this.collapseElementHtmlTags = collapseElementHtmlTags;
    this.collapseControllers = openButton;
    this.isOpen = false;
    this.closeWhenLooseFocus = closeWhenLooseFocus || true;

    this.available = this._isExists(this.collapseElementHtmlTags)
      && this._isExists(this.collapseElementHtmlTags);
  }

  listen() {
    if (!this.available) {
      return;
    }

    Object.keys(this.collapseControllers).map((index) => {
      this.collapseControllers[index].addEventListener('click', (e) => {
        e.preventDefault();
        this._toggle();
      });
    });
  }

  _isExists(htmlElements) {
    return htmlElements
      && typeof htmlElements == 'object'
      && Object.keys(htmlElements).length > 0;
  }

  _toggle() {
    if (!this.available) {
      return;
    }

    this._openAnimation();

    /*
    * Open/Close focused collapse
    * */
    if (this.closeWhenLooseFocus == true) {
      if (!this.isOpen) {
        document.addEventListener('mousedown', this._outsideClickListener.bind(this));
      }
      else {
        document.removeEventListener('mousedown', this._outsideClickListener);
      }
    }

    this.isOpen = !this.isOpen;
  }

  _outsideClickListener(e) {
    // Close collapse if...
    // ... the click event is not on scrollbar
    // ... the provided html elements are available
    // ... the collapsed elements elements are not closed yet
    // ... the click event is not inside a collapsed element
    // ... the click event is not inside a collapse controller
    if (
      e.offsetX > e.target.clientWidth
      || e.offsetY > e.target.clientHeight
      || !this.available
      || this.isOpen !== true
    ) {
      return;
    }

    let clickInside = false;

    Object.keys(this.collapseElementHtmlTags).map((index) => {
      clickInside = clickInside
        || this.collapseElementHtmlTags[index].contains(e.target);
    });

    if (!clickInside) {
      Object.keys(this.collapseControllers).map((index) => {
        clickInside = clickInside
          || this.collapseControllers[index].contains(e.target);
      });
    }

    if (!clickInside) {
      this._toggle();
    }
  }

  _openAnimation() {
    Object.keys(this.collapseElementHtmlTags).map((index) => {
      this.collapseElementHtmlTags[index]
        .classList.remove(
          this.isOpen
            ? 'is-open'
            : 'is-close'
        );
      this.collapseElementHtmlTags[index]
        .classList.add(
          this.isOpen
            ? 'is-close'
            : 'is-open'
        );
      this.collapseElementHtmlTags[index]
        .setAttribute(
          'aria-hidden',
          this.isOpen
            ? 'true'
            : 'false'
        );
      this.collapseElementHtmlTags[index]
        .style.maxHeight =
          this.isOpen
            ? null
            : this.collapseElementHtmlTags[index].innerHTML.length + 'em';
    });

    Object.keys(this.collapseControllers).map((index) => {
      this.collapseControllers[index]
        .classList.remove(
          this.isOpen
            ? 'to-close'
            : 'to-open'
        );
      this.collapseControllers[index]
        .classList.add(
          this.isOpen
            ? 'to-open'
            : 'to-close'
        );
      this.collapseControllers[index]
        .setAttribute(
          'aria-expanded',
          this.isOpen
            ? 'false'
            : 'true'
        );
    });
  }

}

export default collapse;

document.addEventListener('DOMContentLoaded', () => {
  let collapsibleElements,
    collapsibleControllers,
    collapseItem,
    items;

  /* Header : open/close all the menu */
  collapsibleElements = document.querySelectorAll('.header--top--menu, .header--top--menu-main, .header--top--footer');
  collapsibleControllers = document.querySelectorAll('.header--top--button');
  collapseItem = new collapse(collapsibleElements, collapsibleControllers);
  collapseItem.listen();

  /* Header : open/close menu level one by one */
  items = document.querySelectorAll('.header--top--menu-main ul.level_1 > li > button');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].parentNode.querySelectorAll('ul.level_2');
    collapsibleControllers = [items[index]];

    collapseItem = new collapse(collapsibleElements, collapsibleControllers);
    collapseItem.listen();
  });

  /* Header : open/close menu multisite */
  items = document.querySelectorAll('.header--top--multisite--select');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].querySelectorAll('.header--top--multisite--content');
    collapsibleControllers = items[index].querySelectorAll('.header--top--multisite--button');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers);
    collapseItem.listen();
  });

  /* Elto : description */
  items = document.querySelectorAll('.block--elto--description');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].querySelectorAll('.block--elto--description--content');
    collapsibleControllers = items[index].querySelectorAll('.block--elto--description--button');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers, 'false');
    collapseItem.listen();
  });

  /* Catalogue : description */
  items = document.querySelectorAll('.catalogue--description');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].querySelectorAll('.catalogue--description--content');
    collapsibleControllers = items[index].querySelectorAll('.catalogue--description--button');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers, 'false');
    collapseItem.listen();
  });

  /* Catalogue : map */
  /* Catalogue : filter */
  items = document.querySelectorAll('.block--view--filter');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].querySelectorAll('.block--view--filter--content');
    collapsibleControllers = items[index].querySelectorAll('.block--view--filter--button-filter');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers, 'false');
    collapseItem.listen();

    collapsibleElements = items[index].querySelectorAll('.map--wrapper');
    collapsibleControllers = items[index].querySelectorAll('.block--view--filter--button-map');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers, 'false');
    collapseItem.listen();
  });

  /* Catalogue : filters */
  items = document.querySelectorAll('.block-facetapi');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].querySelectorAll('.block-facetapi--content');
    collapsibleControllers = items[index].querySelectorAll('.block-title');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers, 'false');
    collapseItem.listen();
  });

  /* Global */
  items = document.querySelectorAll('.collapse');
  Object.keys(items).map((index) => {
    collapsibleElements = items[index].querySelectorAll('.collapse--content');
    collapsibleControllers = items[index].querySelectorAll('.collapse--button');

    collapseItem = new collapse(collapsibleElements, collapsibleControllers);
    collapseItem.listen();
  });
}, false);
