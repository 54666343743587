import React from 'react';

export default class FieldsetElement extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <fieldset>
        <legend>{this.props.title}&nbsp;{this.props.required ? <sup>*</sup> : ''}</legend>
        <div className={'fieldset-wrapper row'}>
          {this.props.content}
        </div>
      </fieldset>
    );
  }

}
