import React from 'react';
import InputField from './TemplateElement/InputField.jsx';

export default class SubmitField extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    this.props.type = 'submit';

    this.props.attributes = this.props.attributes || {};
    this.props.attributes['onSubmit'] = this.props.onSubmit;

    return (
      <InputField{...this.props}/>
    );
  }

}
