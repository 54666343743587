import fetch from 'cross-fetch';

export const Fetcher = {

  status : null,

  /**
   * Fetch data from url
   *
   * @param url
   * @param method
   * @param args
   * @returns {Promise<Response>}
   */
  request : (url, method = 'get', args = {}) => {
    const options = {
      method : method,
    };

    if (Object.keys(args).length > 0) {
      if (method == 'get') {
        url += Object.keys(args).length
          ? '?' + Fetcher._getQueryString(args)
          : '';
      }
      else {
        options.body = JSON.stringify(args);
      }
    }

    return fetch(url, options)
      .then((response) => {
        Fetcher.status = Fetcher._isSuccess(response) ? 'success' : 'error';

        return Fetcher._getBody(response);
      })
      .then((data) => {
        return {
          status : Fetcher.status,
          query : data,
        };
      })
      .catch((error) => {
        return {
          status : 'error',
          query : {
            error : {error : error.message},
          }
        };
      });
  },

  /**
   * Get parsed error messages
   *
   * @param response
   */
  getParsedErrorMessages : (response) => {
    return typeof response.query !== 'undefined'
      && typeof response.query.error !== 'undefined'
      ? response.query.error
      : {unknown : 'Unknown error'};
  },

  /**
   * Build url parameters
   *
   * @param params
   * @returns {string}
   * @private
   */
  _getQueryString : (params) => {
    let esc = encodeURIComponent;

    return Object.keys(params)
      .map(k => esc(k) + '=' + esc(params[k]))
      .join('&');
  },

  /**
   * Raise AjaxError on error
   *
   * @param response
   * @returns {bool}
   * @private
   */
  _isSuccess : (response) => {
    return typeof response != 'undefined'
      && typeof response.status != 'undefined'
      && response.status >= 200
      && response.status < 300;
  },

  /**
   * Get body of response
   * (Parse response with content-type header)
   *
   * @param response
   * @returns {*|Promise<any>|{}|Promise<string>|string|Blob|Promise<Blob>}
   * @private
   */
  _getBody : (response) => {
    let body = {};

    if (
      !response
      || typeof response == 'undefined'
      || typeof response.headers == 'undefined'
      || typeof response.headers.get('content-type') == 'undefined'
    ) {
      return body;
    }

    let headerContentType = response.headers.get('content-type');

    if (
      headerContentType.indexOf('application/json') !== -1
      || headerContentType.indexOf('application/vnd.api+json') !== -1
    ) {
      body = response.json();
    }
    else if (headerContentType.indexOf('text/html') !== -1) {
      body = response.text();
    }
    else if (
      headerContentType.indexOf('application/pdf') !== -1
      || headerContentType.indexOf('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') !== -1
    ) {
      body = response.blob();
    }

    return body;
  },

};
