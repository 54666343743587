

export const sticky = {
  classNameDefault : 'on-scroll',
  classNameWhenHide : 'on-scroll--hidden',
  classNameWhenShow : 'on-scroll--shown',

  menuHeader : (stickyElement, headerElement, footerElement) => {
    let currentScrollPosition;
    let lastScrollPosition = Math.round(window.pageYOffset);

    let headerPositions = sticky._getPositionsOfElement(headerElement);
    let footerPositions = sticky._getPositionsOfElement(footerElement);

    document.addEventListener('scroll', () => {
      currentScrollPosition = Math.round(window.pageYOffset);

      if (lastScrollPosition == currentScrollPosition) {
        return;
      }

      // Case 1 : scroll enough...
      // Case 2 : scroll not too much...
      // ... to see the sticky element
      if (
        currentScrollPosition > headerPositions.ac
        //&& currentScrollPosition < footerPositions.ab
      ) {
        stickyElement.classList.add(sticky.classNameWhenShow);
        stickyElement.classList.remove(sticky.classNameWhenHide);
      }
      else {
        stickyElement.classList.add(sticky.classNameWhenHide);
        stickyElement.classList.remove(sticky.classNameWhenShow);
      }

      lastScrollPosition = currentScrollPosition;
    });
  },

  /**
   * a
   * |
   * |
   * b --------------+
   * |               |
   * i Element       |
   * |               |
   * c --------------+
   * |
   * |
   * */
  _getPositionsOfElement : (elementHtmlTag) => {
    let positions = {
      ab : Math.round(elementHtmlTag.offsetTop),
      bc : Math.round(elementHtmlTag.offsetHeight),
    };

    return {
      ab : positions.ab,
      bc : positions.bc,
      ac : positions.ab + positions.bc,
      ai : positions.ab + Math.round(positions.bc / 2),
      bi : Math.round(positions.bc / 2),
    };
  },

};

document.addEventListener('DOMContentLoaded', () => {
  let stickyElement = document.querySelector('.header--cta');
  let headerElement = document.querySelector('header.header');
  let footerElement = document.querySelector('footer.footer');

  if (
    typeof stickyElement != 'undefined'
    && stickyElement != null
    && typeof headerElement != 'undefined'
    && headerElement != null
  ) {
    sticky.menuHeader(stickyElement, headerElement, footerElement);
  }
});
