// Fonction pour ajouter un défilement en douceur lors d'un clic sur une balise "a" ou une balise "li"
function smoothScrollToTarget(e) {
  var target = e.target.closest('a, li'); // Récupère l'élément "a" ou "li" le plus proche
  if (target) {
    var href = target.getAttribute('href');
    if (href.startsWith('#')) {
      e.preventDefault(); // Empêche le comportement de lien par défaut

      var targetSection;
      if (target.tagName === 'A') {
        targetSection = document.querySelector(href); // Utilise l'attribut href pour obtenir la section cible
      } else if (target.tagName === 'LI') {
        var anchorElement = target.querySelector('a');
        if (anchorElement) {
          targetSection = document.querySelector(anchorElement.getAttribute('href')); // Utilise l'attribut href de l'élément ancre pour obtenir la section cible
        }
      }

      if (targetSection) {
        targetSection.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }
}

// Ajoute un gestionnaire d'événement de clic sur le document
document.addEventListener('click', smoothScrollToTarget);







